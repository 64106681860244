$c-primary: #ffcd05;
$c-secandary: #ffcd05;
$dots: false;
$btn-text-overrided: black;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'GTWalsheim', Arial, sans-serif;
$font-family-heading: 'GTWalsheim', Arial, sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  position: relative;
  width: 100%;

  .icon {
    position: absolute;
    right: 12px;
    height: 3.75rem;
    top: 0;
    border: none;
    background: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .transparentButton {
    border: none;
    background: none;
    display: block;
    width: 100%;
    text-align: left;
    padding-inline: 0;
  }
}
