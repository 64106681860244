$c-primary: #ffcd05;
$c-secandary: #ffcd05;
$dots: false;
$btn-text-overrided: black;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'GTWalsheim', Arial, sans-serif;
$font-family-heading: 'GTWalsheim', Arial, sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.heading {
  font-family: var(--heading-font-family);
  color: var(--heading-text-color);
  margin-bottom: 1.5rem; //24
  text-align: left;
  font-weight: 600;
  font-size: var(--heading-font-size);
  line-height: 1.1;
  letter-spacing: -0.03125rem; //-0.5px
}
