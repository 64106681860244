$c-primary: #ffcd05;
$c-secandary: #ffcd05;
$dots: false;
$btn-text-overrided: black;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'GTWalsheim', Arial, sans-serif;
$font-family-heading: 'GTWalsheim', Arial, sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
$btn-text: white;
@if variable-exists(btn-text-overrided) {
  $btn-text: $btn-text-overrided;
}

.success-flowv2-screen {
  .feedback-link-container-success {
    position: absolute;
    width: 100%;
    z-index: 10000;
  }

  .wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: left;
    padding: 1.5rem 1.5rem 6rem 1.5rem;
    background: var(--page-background-color);
    z-index: 1000;

    .img-container {
      margin-bottom: 2.5rem; //40
      img {
        max-width: 100%;
        height: 3.375rem; //54
        object-fit: contain;
      }
    }

    .heading {
      padding-bottom: 40px;
    }

    .heading-small {
      font-size: inherit;
      margin-bottom: 0;
    }

    .subheading {
      font-family: var(--heading-font-family) !important;
      color: var(--heading-text-color) !important;
      font-weight: 600;
      font-size: 1.2rem;
    }

    .title {
      font-size: 1rem;
      margin-top: 1.5rem; //24
      white-space: initial;
    }

    .footer {
      text-align: center;
    }
  }
}
