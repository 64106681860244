$c-primary: #ffcd05;
$c-secandary: #ffcd05;
$dots: false;
$btn-text-overrided: black;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'GTWalsheim', Arial, sans-serif;
$font-family-heading: 'GTWalsheim', Arial, sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.content {
  word-break: break-word;

  a {
    font-style: italic;
  }
}
