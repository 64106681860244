$c-primary: #ffcd05;
$c-secandary: #ffcd05;
$dots: false;
$btn-text-overrided: black;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'GTWalsheim', Arial, sans-serif;
$font-family-heading: 'GTWalsheim', Arial, sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
