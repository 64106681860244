$c-primary: #ffcd05;
$c-secandary: #ffcd05;
$dots: false;
$btn-text-overrided: black;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'GTWalsheim', Arial, sans-serif;
$font-family-heading: 'GTWalsheim', Arial, sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
@import '~bootstrap/scss/bootstrap.scss';
//---Setup CSS Variables IDVAAS
$progress-bar: #fff;
@if variable-exists(progress-bar-overided) {
  $progress-bar: $progress-bar-overided;
  $progress-bar-incomplete: darken($progress-bar-overided, 10%);
}

$loading-bg: $c-primary;
@if variable-exists(loading-bg-overrided) {
  $loading-bg: $loading-bg-overrided;
}

$progress-bar-incomplete: $loading-bg;
@if variable-exists(progress-bar-incomplete-overided) {
  $progress-bar-incomplete: $progress-bar-incomplete-overided;
}

$dot: $progress-bar;
@if variable-exists(dot-overrided) {
  $dot: $dot-overrided;
}

$loading-text-color: $progress-bar;
@if variable-exists(loading-text-color-overrided) {
  $loading-text-color: $loading-text-color-overrided;
}

$page-background-color: #fff;
@if variable-exists(page-background-color-overrided) {
  $page-background-color: $page-background-color-overrided;
}
$page-padding-top: 1.5rem; //24
@if variable-exists(page-padding-top-overrided) {
  $page-padding-top: $page-padding-top-overrided;
}
$body-color: #000;
@if variable-exists(body-color-overrided) {
  $body-color: $body-color-overrided;
}
$heading-color: $body-color;
@if variable-exists(heading-color-overrided) {
  $heading-color: $heading-color-overrided;
}
$heading-font-size: 1.6875rem; //27
@if variable-exists(heading-font-size-overrided) {
  $heading-font-size: $heading-font-size-overrided;
}
$base-font-size: 1rem; //16
@if variable-exists(base-font-size-overrided) {
  $base-font-size: $base-font-size-overrided;
}
$flow-v2-header-size: 5rem; //80
@if variable-exists(flow-v2-header-size-overrided) {
  $flow-v2-header-size: $flow-v2-header-size-overrided;
}

//---Loader
$loader-dot1: $c-primary;
$loader-dot2: $c-secandary;
$loader-dot3: $c-primary;
$loader-dot4: $c-secandary;

$even: $c-secandary;
@if $c-primary == $c-secandary {
  $even: lighten($c-secandary, 40%);
  $loader-dot2: lighten($c-secandary, 40%);
  $loader-dot4: lighten($c-secandary, 40%);
}

@if variable-exists(short-loader-dot1) {
  $loader-dot1: $short-loader-dot1;
}
@if variable-exists(short-loader-dot2) {
  $loader-dot2: $short-loader-dot2;
}
@if variable-exists(short-loader-dot3) {
  $loader-dot3: $short-loader-dot3;
}
@if variable-exists(short-loader-dot4) {
  $loader-dot4: $short-loader-dot4;
}

$even: $c-secandary;
@if $c-primary == $c-secandary {
  $even: lighten($c-secandary, 40%);
}
//--Button--
//$btn-primary-text-color: white
//@if variable-exists(btn-text-overrided) {
//    $btn-primary-text-color : $btn-text-overrided;
//}
//$btn-secondary-text-color: $c-secandary;
//@if variable-exists(btn-text-secondary-overrided) {
//    $btn-secondary-text-color : $btn-text-secondary-overrided;
//}
//-----

:root {
  --page-background-color: #{$page-background-color};
  --page-padding-top: #{$page-padding-top};
  --primary: #{$c-primary};
  --secondary: #{$c-secandary};
  --body-text-color: #{$body-color};
  --heading-text-color: #{$heading-color};
  --heading-font-size: #{$heading-font-size};
  --base-font-size: #{$base-font-size};
  --flow-v2-header-size: #{$flow-v2-header-size};
  --base-font-family: #{$base-font-family};
  --heading-font-family: #{$font-family-heading};
  --loading-bg: #{$loading-bg};
  --loading-progress-bar: #{$progress-bar};
  --loading-progress-bar-incomplete: #{$progress-bar-incomplete};
  --loading-dot: #{$dot};
  --loading-text-color: #{$loading-text-color};
  --loading-spinner-dot1: #{$loader-dot1};
  --loading-spinner-dot2: #{$loader-dot2};
  --loading-spinner-dot3: #{$loader-dot3};
  --loading-spinner-dot4: #{$loader-dot4};
  --loading-spinner-evendot: #{$even};
}

body,
html {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: auto;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  color: var(--body-text-color);
  font-family: var(--base-font-family);
  background: $c-grey-light;
  line-height: 1.25rem; //20
}

.enable-one-doc-container {
  color: #494949;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin-top: 0;
  font-family: var(--heading-font-family);
  //font-weight: 600;
}

button:active,
button:focus {
  outline: 0;
}

p,
span,
a,
ul,
ol,
li,
div,
input,
textarea,
button {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
}

a {
  color: var(--secondary) !important;
  &.link-inside-button {
    color: inherit !important;
    height: 100%;
    width: 100%;
    padding-top: 10px;
  }
  text-decoration: underline;
}

b {
  font-weight: 600;
}

.b-container {
  margin: auto;
  padding: 0 1rem;
}

.text-white {
  color: white !important;
}

.text-primary {
  color: var(--primary) !important;
}

.text-secondary {
  color: var(--secondary) !important;
}

.b-splash-black {
  background-color: $c-dark;
  color: white;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 34;
}

.b-splash {
  background-color: var(--loading-bg);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 34;

  /*&:before,
    &:after {
        content: '';
        position: absolute;
        left: 0;
        background-image: url(loading-bg($loading-bg));
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        z-index: -1;
        width: 100%;
        height: 50%;
        @if ($dots) {
            opacity: 1;
        } @else {
            opacity: 0.16;
        }
    }

    &:before {
        top: 0;
    }

    &:after {
        bottom: 0;
        transform: rotate(180deg);
    }*/
}

.b-title {
  font-size: 1rem; //16
  font-weight: 500;
}

.b-icon-warning {
  width: 3.375rem; //54
  height: 3.375rem; //54
  border-radius: 50%;
  background: $c-warning;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6875rem; //27
  font-weight: 500;

  &:before {
    content: '!';
  }
}

.b-loading {
  position: fixed;
  width: 100%;
  height: 100%;
  background: $c-grey-light;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  left: 0;
  top: 0;
  z-index: -1;
}

.b-block {
  border-radius: 0.5rem; //8
  padding: 1rem;
  background: #fff;
  box-shadow: 0 0 0.1px 1px rgba(149, 149, 149, 0.1);
  margin-bottom: 25px;
}

.b-logo {
  max-width: 180px;
  margin-bottom: 10px;
}

.b-enable-exception {
  .image {
    max-width: 300px;
    margin: -15px auto 35px;
    box-shadow: 0 0 83px rgba(170, 170, 170, 0.25);

    img {
      margin-bottom: -10px;
    }
  }

  h3 {
    font-size: 17px;
    max-width: 220px;
    margin: auto;
  }
}
.clearfix:after {
  content: ' '; /* Older browser do not support empty content */
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}
.b-video-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #333;
  transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);

  video {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &::-webkit-media-controls {
      display: none;
    }
  }
}

.b-ul {
  margin: 5px 0;
  padding-left: 18px;

  li {
    margin: 0;
    display: block;
    position: relative;

    &:before {
      position: absolute;
      content: '\2022';
      font-weight: 600;
      color: var(--primary);
      top: 0;
      left: -1.125rem;
    }

    span,
        /*a {
            text-decoration: underline;
            color: var(--primary);
        }*/
        ul {
      list-style-type: circle;
    }
  }
}

.b-hidden-input {
  position: absolute;
  z-index: -1;
  left: -9999;
  opacity: 0;
}

.country_code_desktop {
  width: 100px;
  .select-options {
    width: 230px;
    background-color: white;
  }
  .option {
    border-bottom: 1px solid #e8ebf1;
    font-size: 14px;
  }
  .select-input {
    padding: 4px 0;
    input {
      display: block;
      width: 100%;
      height: calc(1.25rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      color: #495057;
      background-color: #fff;
    }
  }
}

.oli-style-warn {
  li {
    background: url(_img('icons/svg/li-warn.svg')) no-repeat 0 0;
    &:before {
      color: #f8991c;
    }
  }
}

.oli-style-success {
  li {
    background: url(_img('icons/svg/li-success.svg')) no-repeat 0 0;
    &:before {
      color: #00aeb8;
    }
  }
}

.oli-style-default {
  li {
    background: url(_img('icons/svg/li-default.svg')) no-repeat 0 0;
    &:before {
      color: #424876;
    }
  }
}

.bg-black {
  background: #000;
}

.bg-white {
  background: #fff !important;
}

.flex-1 {
  flex: 1;
}

.radio {
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 0.5em;
  font-size: 1rem;
  color: black;

  &.radio-before {
    margin-bottom: 0 !important;
    .radio__control {
      display: grid;
      place-items: center;
    }

    input + .radio__control::before {
      content: '';
      width: 1rem;
      height: 1rem;
      box-shadow: inset 1rem 1rem black;
      border-radius: 50%;
      transition: 180ms transform ease-in-out;
      transform: scale(0);
    }

    input:checked + .radio__control::before {
      transform: scale(1);
    }
  }

  .radio__label {
    transition: 180ms all ease-in-out;
    opacity: 0.8;
    cursor: pointer;
  }

  .radio__input {
    display: flex;
    margin-top: 0.125rem; //2

    input {
      opacity: 0;
      width: 0;
      height: 0;

      /*&:focus + .radio__control {
                box-shadow: 0 0 0 0.05rem #fff, 0 0 0.15rem 0.1rem black;
            }@extend*/
    }
  }

  .radio__control {
    display: block;
    width: 1.375rem; //22
    height: 1.375rem; //22
    border-radius: 50%;
    border: 0.1rem solid black;
    transform: translateY(-0.05rem);
  }
}
